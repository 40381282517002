/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import qs from 'qs';
import moment from 'moment';

import umbraco from '@/config/umbrac.json';

export default {
	name: 'GlobalMixins',
	
	data() {
		return {
			vue_app_type: process.env.VUE_APP_TYPE,
			meta: null,
			globalLoading: false,
			breadcrumbs: null,
			footer: null,
			header: null,
			body: null,
			widgetSections: {}
		}
	},
	
	// child
	metaInfo() {
		return this.meta;
	},
	
	computed: {
		isAuthenticated() {
			return !!this.$store.getters['auth/getToken'];
		},
		currentUser() {
			return this.$store.getters['auth/getUser'];
		},
		currentToken() {
			return this.$store.getters['auth/getToken'];
		},
		globalMessages() {
			return this.$store.getters['globalMessages/list'];
		},
		pageTabs() {
			return this.$store.getters['tabs/list'];
		}
	},
	
	methods: {
		
		checkWidget: async function () {
			const _self = this;
			const options = {
				url: '/abcbridgeapi/check/widget',
				method: 'get',
				qs: {
					sections: true
				}
			};
			
			return this.getSendData(options)
				.then(response => {
					if (response.onlySearch) {
						response.widgetSections = {};
						response.onlySearch.forEach(function (one) {
							_self.widgetSections[one] = true;
							response.widgetSections[one] = true;
						});
					}
					_self.$forceUpdate();
					return response;
				})
				.catch(error => {
					console.error(error);
				});
		},
		
		generateAlphabet(capital = false) {
			return [...Array(26)].map((_, i) => String.fromCharCode(i + (capital ? 65 : 97)))
		},
		
		isActiveMenu(menu) {
			
			if (menu.route && menu.route.url) {
				let l1 = menu.route.url.replace(/(http)[s]?:\/\//, '');
				let l2 = process.env.VUE_APP_DOMAIN.replace(/(http)[s]?:\/\//, '');
				l2 += this.$route.path;
				return (l1 === l2 || l1 === `${l2}/`);
			}
			
			return false;
		},
		
		resetPage(resetMeta = false) {
			if (resetMeta) {
				this.meta = {
					title: {
						value: '',
						template: ''
					},
					route: '',
					meta: [],
					script: [],
					style: [],
					link: [],
					base: {},
					htmlAttrs: {},
					headAttrs: {},
					bodyAttrs: {}
				};
			}
			
			this.breadcrumbs = null;
			this.body = null;
		},
		
		validEmail: function (email) {
			/* eslint-disable */
			let re = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
			return re.test(email);
		},
		
		validPassword: function (pass) {
			/* eslint-enable */
			let re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\s*.!@$%^&(){}[]:;<>,.?\/~_+-=|\\]).{10,32}$/;
			return re.test(pass);
		},
		
		redirectIfAuthenticated: function (destination) {
			this.globalLoading = true;
			if (!destination) {
				destination = {name: "HomePage"};
			}
			if (this.isAuthenticated) {
				this.$router.push(destination);
			} else {
				this.globalLoading = false;
			}
		},
		
		getURLLocalGlobal: function (url) {
			if (!url) {
				return '';
			}
			
			if (url.startsWith("https://abc.herbalgram")) {
				return url;
			}
			if (url.startsWith("http://abc.herbalgram")) {
				return url;
			}
			
			let aliases = process.env.VUE_APP_DOMAIN_ALIAS || "http://herbalgram.org";
			
			if (process.env.VUE_APP_TYPE === 'widget') {
				let mydomain = window.location.origin.replace(/(http)[s]?:\/\//, '');
				if (url.includes(mydomain)) {
					url = url.replace(/(http)[s]?:\/\//, '').replace(mydomain, '');
				}
				if (url !== '#' && url === '/') {
					url = '#/';
				}
			} else {
				let mydomain = process.env.VUE_APP_DOMAIN.replace(/(http)[s]?:\/\//, '');
				
				let localDomainRegex = /(http)[s]?:\/\/(localhost):[0-9]{4}/;
				if (url.includes(mydomain)) {
					url = url.replace(/(http)[s]?:\/\//, '').replace(mydomain, '');
					return url;
				} else if (localDomainRegex.test(url)) {
					return url.replace(localDomainRegex, '');
				} else if (aliases && aliases.trim() !== '') {
					aliases = aliases.split(',');
					if (Array.isArray(aliases) && aliases.length > 0) {
						aliases.forEach(oneAlias => {
							oneAlias = oneAlias.replace(/(http)[s]?:\/\//, '');
							if (url.includes(oneAlias)) {
								url = url.replace(/(http)[s]?:\/\//, '').replace(oneAlias, '');
								return url;
							}
						});
					}
				}
			}
			return url.replace(/\/\/#/g, '/#');
		},
		
		getAnchorFromUrl: function (url) {
			if (url.indexOf('#') !== -1) {
				let newUrl = url.split('#');
				return {url: newUrl[0], anchor: newUrl[1]};
			}
			return null;
		},
		
		getAnchorFromRoute: function () {
			const _self = this;
			if (this.$router.currentRoute.hash) {
				setTimeout(() => {
					let anchor = _self.$router.currentRoute.hash.replace('#', '');
					if (anchor.trim() !== '') {
						_self.scrollMeTo(anchor);
					}
				}, 1000);
			}
		},
		
		goToPage: function (element, $event, cb) {
			$event.preventDefault();
			if (typeof element === 'string') {
				element = {
					route: {
						url: element,
						target: ''
					}
				};
			}
			const myRoute = element.route;
			
			if (myRoute && myRoute.url) {
				let routeUrl = this.getURLLocalGlobal(myRoute.url);
				
				let anchorInUrl = this.getAnchorFromUrl(routeUrl);
				if (anchorInUrl) {
					if (this.$router.currentRoute.path === anchorInUrl.url) {
						this.scrollMeTo(anchorInUrl.anchor);
					} else {
						let page = this.getPage(routeUrl);
						if (page.target && page.target === '_blank') {
							window.open(`${process.env.VUE_APP_DOMAIN}${myRoute.url}`);
						} else {
							this.$router.push({
								path: routeUrl,
								params: page.params
							});
						}
					}
				} else if (routeUrl.includes('http://') || routeUrl.includes('https://')) {
					// external link
					if (myRoute.target && myRoute.target === '_blank') {
						window.open(myRoute.url);
					} else {
						window.location.href = myRoute.url;
					}
				} else if (this.$router.currentRoute.path !== routeUrl) {
					if (element.route.target === "openWidget") {
						window.open(`https://www.herbalgram.org${routeUrl}`, "_blank");
						return;
					} else {
						let page = this.getPage(routeUrl);
						if (page.target && page.target === '_blank') {
							window.open(`${process.env.VUE_APP_DOMAIN}${myRoute.url}`);
						} else {
							this.$router.push({
								path: routeUrl,
								params: page.params
							});
						}
					}
					
				}
			}
			if (cb && typeof cb === 'function') {
				return cb();
			}
		},
		
		getPage: function (myRoute) {
			let routeData = this.$router.resolve(myRoute);
			if (routeData && this.$router.currentRoute.path !== myRoute.url) {
				return {
					route: routeData.route,
					params: myRoute.params || {},
					target: myRoute.target
				};
			} else return null;
		},
		
		licenseError() {
			this.pushMessage({
				type: 'error',
				title: 'Error: 132',
				text: 'The provided license key is not authorized to work from your IP address!'
			});
		},
		
		clearMessages() {
			this.$store.dispatch('globalMessages/clear');
		},
		
		pushMessage(context) {
			this.$store.dispatch('globalMessages/push', {
				type: context.type,
				title: context.title,
				text: context.text
			});
			this.scrollToTop();
		},
		
		scrollToTop() {
			window.scrollTo(0, 0);
		},
		
		executeRequest(context) {
			const APIHeaders = context.headers || {};
			
			APIHeaders['env'] = process.env.VUE_APP_M360_ENV || 'dev';
			APIHeaders['x-key'] = this.$store.getters['key/getKey'];
			
			if (this.$cookies.get('CV_LOGIN_')) {
				APIHeaders['CV_LOGIN_'] = this.$cookies.get('CV_LOGIN_');
			}
			if (this.$cookies.get('JSESSIONID')) {
				APIHeaders['JSESSIONID'] = this.$cookies.get('JSESSIONID');
			}
			const requestOptions = {
				url: context.url,
				method: (context.method || 'GET').toUpperCase(),
				headers: APIHeaders,
			};
			
			if (!['POST', 'PATCH', 'PUT'].includes(requestOptions.method)) {
				requestOptions.params = context.params;
			} else {
				requestOptions.data = context.params;
			}
			
			if (context.qs) {
				requestOptions.params = context.qs;
			}
			
			if (context.body) {
				requestOptions.data = context.body;
			}
			
			if (context.blob) {
				requestOptions.responseType = 'blob';
			}
			
			requestOptions.paramsSerializer = function (params) {
				return qs.stringify(params, {arrayFormat: 'repeat'});
			};
			
			return this.$http(requestOptions)
				.then(response => {
					if (context.blob) {
						return Promise.resolve(response);
					}
					
					if (!response.data.data) {
						console.error('Invalid response format: ' + JSON.stringify(response.data));
						return response.data;
					}
					let myData = response.data.data;
					return myData;
				}).catch(error => {
					// normalize error
					const errorRes = {};
					if (error.response) {
						if (error.response.status) errorRes.status = error.response.status;
						if (error.response.data) {
							if (error.response.data.errors) {
								errorRes.errors = error.response.data.errors;
							} else if (error.response.data.error) {
								const code = Object.keys(error.response.data.statusCode)[0];
								errorRes.errors = {};
								errorRes.errors[code] = {
									code,
									message: error.response.data.message
								};
							}
						}
					}
					if (!errorRes.status) errorRes.status = 500;
					if (!errorRes.errors) errorRes.errors = {
						'500': {
							code: 500,
							message: 'An internal error occurred'
						}
					};
					const code = Object.keys(errorRes.errors)[0];
					errorRes.message = errorRes.errors[code].message;
					// throw normalized error
					throw errorRes;
				});
		},
		
		async getSendData(context) {
			const self = this;
			
			function forceLogout(errors) {
				self.$cookies.remove('token');
				self.LogoutCookies();
				self.clearMessages();
				
				if (self.$router.currentRoute.name !== 'Login') {
					self.$router.push({name: "Login"});
				}
				
				setTimeout(() => {
					printErrors(errors);
				}, 1000);
			}
			
			function printErrors(errors) {
				for (let code in errors) {
					self.$store.dispatch('globalMessages/push', {
						type: 'error',
						title: `Error ${code}`,
						text: errors[code].message
					});
				}
				self.scrollToTop();
			}
			
			if (!context.noLoading) {
				self.$emit('triggerOverlayLoading', true);
			}
			
			self.clearMessages();
			return self.executeRequest(context)
				.then(data => data)
				.catch(error => {
					if (error.message === "Invalid auth token!") {
						console.error(error.message);
						self.LogoutCookies();
					}
					if (context.bypass) {
						throw error;
					} else if (error.status === 520 && error.errors['142'] || error.errors['1008']) {
						if (context.method === 'delete' && context.url === process.env.VUE_APP_LOGIN_API) {
							forceLogout(error.errors);
							throw error;
						} else {
							forceLogout(error.errors);
							throw error;
						}
					} else {
						if (!context.skipErrors) {
							printErrors(error.errors);
						}
						throw error;
					}
				}).finally(() => {
					if (!context.noLoading) {
						self.$emit('triggerOverlayLoading', false);
					}
				});
		},
		
		async checkFixProperties(section) {
			
			const _self = this;
			const reservedElements = ['elements', 'route', 'sections', 'defaultStyle', 'aspectRatio'];
			
			function fixPropertyName(oldName) {
				if (typeof oldName !== 'string') {
					return oldName;
				}
				
				if (umbraco && umbraco[oldName]) {
					return umbraco[oldName]
				} else {
					let newName = '';
					if (oldName.includes('elevate')) {
						newName = oldName;
					} else {
						newName = oldName.replace('ele', '');
					}
					newName = newName.charAt(0).toLowerCase() + newName.slice(1);
					if (/^([a-z]+)(([A-Z]([a-z]+))+)$/.test(newName)) {
						newName = _self._.snakeCase(newName).replace(/[_]/g, '-');
						
						if (!newName.includes('v-')) {
							newName = `v-${newName}`;
						}
					}
					return newName;
				}
			}
			
			function fixPropertiesNames(properties) {
				for (let key in properties) {
					let newKey = fixPropertyName(key);
					if (key !== newKey) {
						properties[newKey] = _self._.cloneDeep(properties[key]);
						delete properties[key];
					}
				}
			}
			
			function iterateUpdateSectionProperties(section) {
				if (Array.isArray(section)) {
					for (let i = 0; i < section.length; i++) {
						iterateUpdateSectionProperties(section[i]);
					}
					_self.$forceUpdate();
				} else if (typeof (section) === 'object') {
					for (let i in section) {
						if (i === 'type') {
							section.vuetify = fixPropertyName(section.type);
							if (
								section.type == 'eleAbstract' &&
								section.properties &&
								section.properties.template
							) {
								if (section.properties.data.items) {
									section.properties.data.items.forEach((oneItem) => {
										for (let i in oneItem) {
											if (
												typeof oneItem[i] === 'string' &&
												(oneItem[i].includes('http://') || oneItem[i].includes('https://'))
											) {
												oneItem[i] = _self.getURLLocalGlobal(oneItem[i]);
											}
										}
									});
								}
								if (section.properties.data.medias) {
									section.properties.data.medias.forEach((oneItem) => {
										for (let i in oneItem) {
											if (
												typeof oneItem[i] === 'string' &&
												(oneItem[i].includes('http://') || oneItem[i].includes('https://'))
											) {
												oneItem[i] = _self.getURLLocalGlobal(oneItem[i]);
											}
										}
									});
								}
								
								if (section.properties.template.includes(':displayType=')) {
									let type = section.properties.template.match(/(displayType=")[a-z0-9A-Z]+"/);
									type = type[0].replace(type[1], '').replace('"', "");
									section.type = 'ele' + type;
									section.vuetify = fixPropertyName(section.type);
									section.properties.template = section.properties.template.replace(`:displayType="${type}"`, '');
								}
								_self.$forceUpdate();
							}
							_self.$forceUpdate();
						} else if (i === 'properties') {
							//adjust special reserved words
							reservedElements.forEach((oneReservedElement) => {
								if (section.properties[oneReservedElement]) {
									section[oneReservedElement] = _self._.cloneDeep(section.properties[oneReservedElement]);
									delete section.properties[oneReservedElement];
									for (let j = 0; j < section[oneReservedElement].length; j++) {
										iterateUpdateSectionProperties(section[oneReservedElement][j]);
									}
									_self.$forceUpdate();
								}
							});
							
							fixPropertiesNames(section.properties);
							_self.$forceUpdate();
						} else {
							iterateUpdateSectionProperties(section[i]);
							_self.$forceUpdate();
						}
					}
				}
			}
			
			iterateUpdateSectionProperties(section);
		},
		
		async loadDataInfo(pageUrl, meta = null) {
			if (!pageUrl || pageUrl.trim() === '') {
				return null;
			}
			
			this.$emit('triggerOverlayLoading', true);
			let requestOptions = {
				url: '/abcbridgeapi/',
				method: 'post',
				body: {
					route: pageUrl
				}
			};
			
			if (meta) {
				requestOptions.body.meta = meta;
			}
			return this.getSendData(requestOptions)
				.then(response => {
					if (response) {
						return response;
					}
					this.$emit('triggerOverlayLoading', false);
				})
				.catch(error => {
					if (error && error.errors && error.errors[132] && error.errors[132].message) {
						this.clearMessages();
						this.licenseError();
					} else throw error;
				});
		},
		
		stripHtml(html) {
			let div = document.createElement("DIV");
			div.innerHTML = html;
			return div.textContent || div.innerText || "";
		},
		
		stripPTag(html) {
			let doc = new DOMParser().parseFromString(html, 'text/html')
			return doc.querySelector('p').innerHTML || ''
		},
		
		async updatePageMetaInfo(contextParam) {
			let existingMetaData = this.$meta().refresh();
			// if new page
			//if the page has the a different url, then clean it.
			if (existingMetaData.metaInfo && existingMetaData.metaInfo.route) {
				if (this.$router.currentRoute.path !== existingMetaData.metaInfo.route) {
					existingMetaData.metaInfo.meta = [];
				}
			}
			
			let context = this._.cloneDeep(contextParam);
			
			if (context.name) {
				//Set Category section
				let category = 'General';
				if (context.paths) {
					if (context.paths[0].name === "News") {
						category = 'News';
					} else if (context.paths[0].name === "Resources") {
						if (context.paths[1] && context.paths[1].name) {
							category = context.paths[1].name;
						}
					}
				}
				
				let catFound = false;
				for (let i = 0; i < existingMetaData.metaInfo.meta.length; i++) {
					if (existingMetaData.metaInfo.meta[i].vmid === "categoryName") {
						catFound = true;
						break;
					}
				}
				if (!catFound) {
					if (category.trim() !== '') {
						existingMetaData.metaInfo.meta.push({
							vmid: 'categoryName',
							name: 'addsearch-custom-field',
							'data-type': 'text',
							content: `category=${category}`
						});
					}
					
				}
				//end Category section
				existingMetaData.metaInfo.meta.push({
					vmid: 'og:title',
					name: 'og:title',
					property: 'og:title',
					content: context.name
				});
				
				existingMetaData.metaInfo.meta.push({
					vmid: 'og:site_name',
					name: 'og:site_name',
					property: 'og:site_name',
					content: context.name
				});
				
				existingMetaData.metaInfo.meta.push({
					vmid: 'addsearch:site_name',
					name: 'addsearch-custom-field',
					'data-type': 'text',
					content: `title=${context.name}`
				});
				
				existingMetaData.metaInfo.title = existingMetaData.metaInfo.titleChunk = this.stripHtml(context.name);
			}
			
			if (context.title || context.seoTitle) {
				let seoTitle = context.seoTitle ?? context.title;
				let found = false;
				existingMetaData.metaInfo.meta.forEach((oneMeta) => {
					if (oneMeta.vmid === 'og:title') {
						found = true;
						oneMeta.content = this.stripHtml(seoTitle)
					}
					
					if (oneMeta.vmid === 'og:site_name') {
						found = true;
						oneMeta.content = this.stripHtml(seoTitle)
					}
					
					if (oneMeta.vmid === 'addsearch:site_name') {
						found = true;
						oneMeta.content = this.stripHtml(seoTitle)
					}
				});
				if (!found) {
					existingMetaData.metaInfo.meta.push({
						vmid: 'og:title',
						name: 'og:title',
						property: 'og:title',
						content: this.stripHtml(seoTitle)
					});
					existingMetaData.metaInfo.meta.push({
						vmid: 'og:site_name',
						name: 'og:site_name',
						property: 'og:site_name',
						content: this.stripHtml(seoTitle)
					});
					existingMetaData.metaInfo.meta.push({
						vmid: 'addsearch:site_name',
						name: 'addsearch-custom-field',
						'data-type': 'text',
						content: `title=${this.stripHtml(seoTitle)}`
					});
				}
				
				existingMetaData.metaInfo.title = existingMetaData.metaInfo.titleChunk = this.stripHtml(seoTitle);
			}
			
			if (context.description || context.metaDescription) {
				let metaDescription = context.metaDescription ?? context.description;
				let found = false;
				existingMetaData.metaInfo.meta.forEach((oneMeta) => {
					if (oneMeta.vmid === 'og:description') {
						found = true;
						oneMeta.content = metaDescription
					}
					
					if (oneMeta.vmid === 'og:description') {
						found = true;
						oneMeta.content = metaDescription
					}
				});
				if (!found) {
					existingMetaData.metaInfo.meta.push({
						vmid: 'og:description',
						name: 'og:description',
						property: 'og:description',
						content: metaDescription
					});
					existingMetaData.metaInfo.meta.push({
						vmid: 'description',
						name: 'description',
						content: metaDescription
					});
					existingMetaData.metaInfo.meta.push({
						name: 'addsearch-custom-field',
						'data-type': 'text',
						content: `description=${metaDescription}`
					});
				}
			}
			
			if (context.favicon) {
				existingMetaData.link.push({
					vmid: 'icon',
					rel: 'icon',
					href: context.favicon
				});
				
				existingMetaData.metaInfo.meta.push({
					vmid: 'favicon',
					name: 'favicon',
					href: context.favicon
				});
			}
			
			if (context.modified) {
				let mydate = context.modified;
				
				if (context.datePublished) {
					mydate = context.datePublished;
				} else if (context.date) {
					mydate = context.date;
				}
				
				existingMetaData.metaInfo.meta.push({
					name: 'addsearch-custom-field',
					'data-type': 'date',
					content: `modified=${new Date(mydate).toISOString()}`
				});
			}
			
			if (context.protected) {
				// TODO: check this
				for (let i = existingMetaData.metaInfo.meta.length - 1; i >= 0; i--) {
					if (existingMetaData.metaInfo.meta[i].vmid === 'protected') {
						existingMetaData.metaInfo.meta.splice(i, 1);
						break;
					}
				}
				
				existingMetaData.metaInfo.meta.push({
					vmid: 'protected',
					name: 'addsearch-custom-field',
					'data-type': 'keyword',
					content: `type=Protected`
				});
				
				let groups = ``;
				context.protected.forEach((oneProtectedGroup) => {
					groups += `group=${oneProtectedGroup};`;
				});
				
				existingMetaData.metaInfo.meta.push({
					vmid: 'protected-groups',
					name: 'addsearch-custom-field',
					'data-type': 'keyword',
					content: groups
				});
			} else {
				for (let i = existingMetaData.metaInfo.meta.length - 1; i >= 0; i--) {
					if (existingMetaData.metaInfo.meta[i].vmid === 'protected') {
						existingMetaData.metaInfo.meta.splice(i, 1);
					} else if (existingMetaData.metaInfo.meta[i].vmid === 'protected-groups') {
						existingMetaData.metaInfo.meta.splice(i, 1);
					}
				}
				
				existingMetaData.metaInfo.meta.push({
					vmid: 'protected',
					name: 'addsearch-custom-field',
					'data-type': 'keyword',
					content: `type=Free`
				});
			}
			
			if (context.pageImage) {
				existingMetaData.metaInfo.meta.push({
					name: 'og:image',
					property: 'og:image',
					content: context.pageImage
				});
				existingMetaData.metaInfo.meta.push({
					name: 'msapplication-TileImage',
					content: context.pageImage
				});
			}
			
			if (context.global) {
				if (context.global.link && Array.isArray(context.global.link)) {
					context.global.link.forEach((oneLink) => {
						this.meta.link.push({
							rel: 'stylesheet',
							href: oneLink
						});
					})
				}
				
				if (context.global.scripts && Array.isArray(context.global.scripts)) {
					context.global.scripts.forEach((oneScript) => {
						this.meta.script.push({
							async: true,
							defer: true,
							src: oneScript
						});
					})
				}
			}
			
			if (context.msTilePicker) {
				existingMetaData.metaInfo.meta.push({
					name: 'msapplication-TileColor',
					content: context.msTilePicker
				});
			}
			
			if (context.chromeThemeColor) {
				existingMetaData.metaInfo.meta.push({
					name: 'theme-color',
					content: context.chromeThemeColor
				});
			}
			
			if (context.metadata) {
				context.metadata.forEach((oneMetaData) => {
					if (oneMetaData.properties) {
						existingMetaData.metaInfo.meta.push({
							name: oneMetaData.properties.metaName,
							'data-type': oneMetaData.properties['data-type'] || 'text',
							content: oneMetaData.properties.content
						});
					}
				});
			}
			
			// Setting the seo search options
			let robots = {
				vmid: 'robots',
				name: 'robots',
				content: ''
			};
			
			// by default, settings are true
			let indexing = "index";
			let follow = "follow";
			
			if (Object.hasOwnProperty.call(context, 'searchEngineVisible')) {
				if (context.searchEngineVisible === false) {
					indexing = "noindex";
				}
			}
			if (Object.hasOwnProperty.call(context, 'searchEngineCrawl')) {
				if (context.searchEngineCrawl === false) {
					follow = "nofollow";
				}
			}
			robots.content = `${indexing}, ${follow}`;
			let robotsFound = false;
			for (let i = 0; i < existingMetaData.metaInfo.meta.length; i++) {
				if (existingMetaData.metaInfo.meta[i].vmid === robots.vmid) {
					existingMetaData.metaInfo.meta[i] = robots;
					robotsFound = true;
					break;
				}
			}
			if (!robotsFound) {
				existingMetaData.metaInfo.meta.push(robots);
			}
			
			// remove duplicates
			// existingMetaData.metaInfo.meta = this._.uniqBy(existingMetaData.metaInfo.meta, 'name');
			
			if (!this._.isEqual(this.meta, existingMetaData)) {
				this.meta = existingMetaData.metaInfo;
				this.meta.route = this.$router.currentRoute.path;
				this.$forceUpdate();
			}
			
			return true;
		},
		
		async checkSetElementBackground(context, section = false) {
			if (context.properties && context.properties.background && context.properties.background.length > 0) {
				context.background = {};
				let selectedBackground = this._.cloneDeep(context.properties.background[0]);
				
				if (selectedBackground.type === 'video') {
					context.background.video = {
						media: {},
						properties: {}
					};
					context.background.video.media = {
						type: selectedBackground.properties.type,
						src: selectedBackground.properties.srcCrop || selectedBackground.properties.src
					};
					delete selectedBackground.properties.type;
					delete selectedBackground.properties.src;
					delete selectedBackground.properties.srcCrop;
					
					context.background.video.properties.poster = selectedBackground.properties.videoPosterCrop || selectedBackground.properties.videoPoster;
					delete selectedBackground.properties.videoPoster;
					delete selectedBackground.properties.videoPosterCrop;
					
					if (!selectedBackground.properties.style) {
						selectedBackground.properties.style = {};
					}
					
					selectedBackground.properties.style['object-fit'] = 'cover';
					if (context.properties && context.properties.style && context.properties.style.height) {
						selectedBackground.properties.style['height'] = context.properties.style.height;
					}
					this._.merge(context.background.video.properties, selectedBackground.properties);
				} else if (selectedBackground.type === 'backgroundImage') {
					context.background.image = this._.cloneDeep(selectedBackground.properties);
					
					let holder = (section) ? context.bg : context.properties;
					if (!holder) {
						holder = {};
					}
					
					if (!holder.style) {
						holder.style = {};
					}
					
					holder.style['background-image'] = `url(${context.background.image.srcCrop || context.background.image.src})`;
					
					if (context.background.image.size) {
						holder.style['background-size'] = context.background.image.size;
					}
					
					if (context.background.image.repeat) {
						holder.style['background-repeat'] = context.background.image.repeat.toLowerCase();
					}
					
					if (context.background.image.position) {
						holder.style['background-position'] = context.background.image.position.toLowerCase();
					}
					
					if (section) {
						context.bg = holder;
					} else {
						context.properties = holder;
					}
				} else if (selectedBackground.type === 'backgroundColor') {
					context.background.color = this._.cloneDeep(selectedBackground.properties);
					
					let holder = (section) ? context.bg : context.properties;
					if (!holder) {
						holder = {};
					}
					
					if (!holder.style) {
						holder.style = {};
					}
					holder.style['background-color'] = `${context.background.color.color} !important`;
					
					if (section) {
						context.bg = holder;
					} else {
						context.properties = holder;
					}
				}
				
				if (section) {
					if (!context.bg) {
						context.bg = {};
					}
					if (!context.bg.class) {
						context.bg.class = '';
					}
					context.bg.class += ' m360-relative-section';
				} else {
					if (!context.properties.class) {
						context.properties.class = '';
					}
					
					context.properties.class += ' m360-relative-section';
				}
				
				delete context.properties.background;
				this.$forceUpdate();
				return true;
			} else
				return true;
		},
		
		doLogout() {
			const _self = this;
			if (confirm('Are you sure you want to log out ?')) {
				this.$emit('triggerOverlayLoading', true);
				
				let requestOptions = {
					url: '/abcbridgeapi/logout',
					method: 'delete',
					qs: {}
				};
				
				localStorage.removeItem('rememberMe');
				
				return this.getSendData(requestOptions)
					.then(() => {
						_self.LogoutCookies();
						_self.$emit('triggerOverlayLoading', false);
						_self.$nextTick(() => {
							window.location.reload();
						});
					});
			}
		},
		
		LogoutCookies() {
			if (process.env.VUE_APP_DEVELOPMENT === "local") {
				this.$cookies.remove('CV_LOGIN_');
				this.$cookies.remove('JSESSIONID');
			} else {
				document.cookie = "JSESSIONID=;domain=.herbalgram.org;path=/; expires=Thu, 01 Jan 1990 00:00:00 GMT";
				document.cookie = "HGSESSIONID=;domain=.herbalgram.org;path=/; expires=Thu, 01 Jan 1990 00:00:00 GMT";
				document.cookie = "CV_LOGIN_=;domain=.herbalgram.org;path=/; expires=Thu, 01 Jan 1990 00:00:00 GMT";
			}
			this.$store.dispatch('auth/clear');
		},
		
		async hookAllLinks() {
			const _self = this;
			let elements = document.getElementsByTagName('a');
			let domain = window.location.origin.replace(/(http)[s]?:\/\//, '');
			if (elements) {
				if (Array.isArray(elements) && elements.length > 0) {
					elements.forEach((oneLinkElement) => {
						if (typeof oneLinkElement === 'object') {
							updateElementLinkValue(oneLinkElement);
						}
					});
				} else if (typeof elements === 'object') {
					for (let oneLinkElement in elements) {
						if (typeof elements[oneLinkElement] === 'object') {
							updateElementLinkValue(elements[oneLinkElement]);
						}
					}
				}
			}
			
			function updateElementLinkValue(oneLinkElement) {
				if (!oneLinkElement.linkUpdated && oneLinkElement.href && oneLinkElement.href !== '#') {
					if (oneLinkElement.href.includes(domain)) {
						oneLinkElement.onclick = function (event) {
							
							event.preventDefault();
							// extract the href value as added in code
							let fullhref = oneLinkElement.href;
							
							if (process.env.VUE_APP_TYPE === 'widget' && oneLinkElement.target !== "openWidget") {
								let mydomain = window.location.origin.replace(/(http)[s]?:\/\//, '');
								if (fullhref.includes(mydomain)) {
									if (oneLinkElement.target) {
										// stop target in widget
										oneLinkElement.target = '';
									}
									if (oneLinkElement.attributes['href'].nodeValue.indexOf('#/') === 0) {
										let nodeValue = oneLinkElement.attributes['href'].nodeValue.replace('#/', '/');
										fullhref = window.location.origin + nodeValue;
									}
								}
							}
							oneLinkElement.href = _self.getURLLocalGlobal(fullhref);
							
							let option = {
								url: oneLinkElement.href,
								target: oneLinkElement.target
							}
							_self.goToPage({
								route: option
							}, event);
						};
					}
				}
				oneLinkElement.linkUpdated = true;
			}
		},
		
		testLogin() {
			const _self = this;
			let requestOptions = {
				url: '/abcbridgeapi/user',
				method: 'get',
				bypass: true
			};
			if (!this.$cookies.get('JSESSIONID')) {
				_self.LogoutCookies();
				return;
			}
			return _self.getSendData(requestOptions)
				.then((response) => {
					if (!response || !response.email) {
						_self.LogoutCookies();
					}
					let waitTime = 60 * 1000 * 5;// 5 minutes
					setTimeout(async () => {
						_self.testLogin();
					}, waitTime);
				}).catch((error) => {
					console.error(error);
					_self.LogoutCookies();
				});
		},
		
		async setPageMeta(pageInfo) {
			await this.updatePageMetaInfo(pageInfo);
			
			if (pageInfo.footer) {
				this.footer = pageInfo.footer;
			} else if (pageInfo.disableFooter) {
				this.footer = null;
			}
			
			if (pageInfo.header) {
				if (Array.isArray(pageInfo.header)) {
					this.header = pageInfo.header;
				} else {
					this.header = [pageInfo.header];
				}
			} else if (pageInfo.disableHeader) {
				this.header = null;
			}
			if (pageInfo.paths) {
				this.breadcrumbs = pageInfo.paths;
			}
			
			if (pageInfo.menu) {
				this.triggerRerenderMenu(pageInfo.menu);
			}
			
			//no header was sent via globals nor via the override
			//header might also been disabled, same use case
			if (!this.header) {
				this.$emit('headerSuppressed', true);
			}
		},
		
		async setGlobalDefaults() {
			this.globals = this._.cloneDeep(this.defaultGlobals);
			await this.updatePageMetaInfo(this.globals);
			
			if (this.globals.header) {
				this.header = this.globals.header;
			}
			
			if (this.globals.footer) {
				this.footer = this.globals.footer;
			}
		},
		
		scrollMeTo(refName) {
			let element = this.$refs[refName];
			if (!element) {
				element = document.getElementById(refName);
			}
			if (element) {
				let top = element.offsetTop;
				window.scrollTo({
					top: top,
					left: 0,
					behavior: 'smooth'
				});
			}
		},
		
		changeTab(tabsName, oneTabItem, tabIndex) {
			this.elementTab = tabIndex;
			this.$emit('tabsChanged', tabsName, tabIndex);
		},
		
		tabsChanged(tabsName, tabsIndex) {
			this.$store.dispatch('tabs/set', [tabsName, tabsIndex]);
			let pageTabs = this._.cloneDeep(this.$store.getters['tabs/list']);
			this.$store.dispatch('tabs/clear');
			this.$nextTick(() => {
				this.$nextTick(() => {
					this.$store.dispatch('tabs/reload', pageTabs);
					this.$forceUpdate();
				});
			})
		},
		
		stripTags(data) {
			if (data) {
				data = data.replace(/(<([^>]+)>)/ig, '');
			}
			return data;
		},
		
		extractNumber(data) {
			let thenum = 0;
			if (data) {
				if (typeof data === 'string') {
					// replace all leading non-digits with nothing
					thenum = data.replace(/^\D+/g, '');
					thenum = parseInt(thenum);
				}
			}
			return thenum;
		},
		
		updateMetaData: function (obj) {
			this.$emit('pageMeta', obj);
		},
		
		forceRerender() {
			// Removing my-component from the DOM
			this.renderComponent = false;
			
			this.$nextTick(() => {
				// Adding the component back in
				this.renderComponent = true;
			});
		},
	},
	
	watch: {
		pageTabs: {
			handler(newV) {
				if (newV && newV.tabs && this.component && this.component.refTabs) {
					this.elementTab = newV.tabs[this.component.refTabs];
					this.$forceUpdate();
				}
			},
			deep: true
		}
	},
	
	filters: {
		'stripHtml': function (value) {
			if (!value) {
				return '';
			}
			const div = document.createElement('div');
			div.innerHTML = value;
			return div.textContent || div.innerText || ''
		},
		'stripPTag': function (html) {
			let doc = new DOMParser().parseFromString(html, 'text/html');
			return doc.querySelector('p').innerHTML || '';
		},
		'formatDate': function (value) {
			if (value) {
				return moment(String(value)).format('MM-DD-YYYY')
			}
		},
		'formatAuthorName': function (name) {
			if (name.includes(',')) {
				var names = name.split(',').map(function (item) {
					return item.trim();
				});
				names.unshift(names.pop());
				return names.join(' ');
			}
			return name;
		}
	}
}